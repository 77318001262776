import * as Sentry from '@sentry/browser';
import { VueIntegration } from '@sentry/vue';
import Vue from 'vue';
import Toasted from 'vue-toasted';
import router from '@/common/router';
import store from '@/common/store';
import titleMixin from '@/common/mixins/titles';
import IdleVue from 'idle-vue';
import { isEnabled } from 'vue-feature-flipping';
import { Feature } from '@/features.types';
import App from './App.vue';

import '@/assets/css/app.css';
import setVueFilters from './filters';
import setVuePlugins from './plugins';
import setFeatureToggles from './features';
import vuetify from './plugins/vuetify';

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new VueIntegration({ Vue, logErrors: true })],
});

Vue.config.productionTip = false;
Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 5000,
  keepOnHover: true,
  className: 'toast',
  router,
});
setVuePlugins(Vue);
Vue.mixin(titleMixin);
setVueFilters(Vue);

setFeatureToggles();

const eventsHub = new Vue({
  router,
  store,
  created: () => store.dispatch('init'),
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

function invokeEventsHub() {
  return eventsHub;
}

if (isEnabled(Feature.AUTO_LOGOUT)) {
  Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 840000, // 14 mins until modal pops up for session timeout
    startAtIdle: false,
  });
} else {
  invokeEventsHub();
}
