export default function getDomain(prefix: string): string {
  const domains: any = {
    'cgatewaystaging.link': {
      clientId: 3,
    },
    'coregateway.local': {
      clientId: 1,
    },
  };
  const useprefix = prefix ? `${prefix}.` : '';
  const host = window.location.hostname;
  const domainParts = host.split('.').slice(1);
  const domain = domainParts.join('.');
  if (domains[domain]) {
    return `https://${useprefix}${domain}`;
  }
  return process.env.VUE_APP_API_BASE_URL;
}
