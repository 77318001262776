<template>
  <div>
    <v-data-table
      dense
      height="30vh"
      :headers="headers"
      :items="webhooks"
      :options.sync="options"
      :multi-sort="true"
      :server-items-length="meta.total"
      :header-props="{
        sortIcon: 'mdi-arrow-down',
      }"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="pr-2">Webhooks</v-toolbar-title>
          <v-btn x-small color="secondary" @click="openNewWebhookModal">Add</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        {{ item.name | capitalize }}
      </template>
      <template v-slot:item.required="{ item }">
        <v-icon color="#23b912" v-if="item.url">$check</v-icon>
        <v-icon color="#FF0000" v-else>$times</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click="editWebhook(item)" v-bind="attrs" v-on="on">
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit Webhook</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click="promptForDelete(item)" v-bind="attrs" v-on="on">
              mdi-delete
            </v-icon>
          </template>
          <span>Delete Webhook</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showDeleteModal"
      @keydown.esc="showDeleteModal = false"
      persistent
      max-width="600"
    >
      <v-card v-if="webhookToDelete" justify="center" class="p-3">
        <v-card-title>
          Are you sure you want to delete webhook {{ ` ${webhookToDelete.name}` }}?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn small color="secondary" @click="showDeleteModal = false">No </v-btn>
          <v-btn small color="secondary" @click="deleteWebhook">Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LoadingModal :loadingText="loadingText" :showModal="isSubmitting" />
    <WebhookNew ref="newModal" @created="getData" :hpps="hpps" />
    <WebhookEdit ref="editModal" @updated="getData" :webhookToEdit="webhookToEdit" :hpps="hpps"/>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import WebhookNew from '@/common/components/support/WebhookNew.vue';
import WebhookEdit from '@/common/components/support/WebhookEdit.vue';
import api from '@/common/api';
import { Webhook } from '@/common/components/support/support.types';
import { JsonApiArrayResponse } from '@/jsonApi.types';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { HostedPaymentPageResponse } from '@/common/types/hostedPaymentPages.types';

export default Vue.extend({
  data() {
    return {
      loading: true,
      hpps: [] as HostedPaymentPageResponse[],
      meta: {} as any,
      showDeleteModal: false as boolean,
      webhooks: [] as Webhook[],
      options: {} as any,
      webhookToDelete: null as Webhook | null,
      webhookToEdit: {} as object,
      isSubmitting: false as boolean,
      loadingText: '' as string,
      headers: [
        {
          text: 'id',
          value: 'id',
          sortable: false,
          align: ' d-none',
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
        {
          text: 'URL',
          value: 'url',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
      ],
      errors: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    showDeleteModal() {
      if (!this.showDeleteModal) {
        this.webhookToDelete = null;
      }
    },
  },

  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.options;
      const params: any = {
        page,
        perPage: itemsPerPage === -1 ? this.meta.total : itemsPerPage,
        role: '',
        sortBy,
        sortDesc,
      };
      this.loading = true;
      api
        .get('/webhooks', { params })
        .then(this.assignWebhooks)

        .catch(() => {
          this.webhooks = [];
        })
        .finally(() => {
          this.loading = false;
        });

      api
        .get('hosted-payments')
        .then(({ data }) => {
          this.hpps = data.data!;
        });
    },
    assignWebhooks({ data }: { data: JsonApiArrayResponse<Webhook> }) {
      this.meta = data.meta;
      this.webhooks = data.data!;
    },
    promptForDelete(webhook: Webhook) {
      this.showDeleteModal = true;
      this.webhookToDelete = webhook;
    },
    submitActions(isSubmitting: boolean, loadingText: string = 'Deleting...') {
      this.isSubmitting = isSubmitting;
      this.loadingText = loadingText;
    },
    deleteWebhook() {
      this.showDeleteModal = false;
      this.submitActions(true);
      api
        .delete(`/webhooks/${this.webhookToDelete?.id}`)
        .then(() => {
          this.webhookToDelete = null;
          this.getData();
        })
        .catch(({ response }: { response: any }) => {
          this.$toasted.error(`There was a problem deleting the webhook. (${response.status})`);
        })
        .finally(() => {
          this.submitActions(false);
        });
    },
    openNewWebhookModal() {
      (this.$refs.newModal as any).show();
    },
    editWebhook(webhook: Webhook) {
      this.webhookToEdit = webhook;
      (this.$refs.editModal as any).show();
    },
  },
  components: { WebhookNew, WebhookEdit, LoadingModal },
});
</script>
