const digestMessage = (message) => window.crypto.subtle.digest(
  'SHA-256', new TextEncoder().encode(message),
);

const generateCodeChallenge = (verifier) => digestMessage(verifier).then(
  (bytes) => btoa(String.fromCharCode(...new Uint8Array(bytes))),
);

const base64UrlEncode = (str) => str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');

const randomString = (length) => {
  let rand = '';

  while (rand.length < length) {
    rand += Math.random().toString(36).slice(2);
  }

  return rand.substring(0, length - 1);
};

export { generateCodeChallenge, base64UrlEncode, randomString };
