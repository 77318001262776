import { Feature } from '@/features.types';

export default [
  {
    route: 'iso.dashboard',
    icon: '$tachometer-alt',
    text: 'Dashboard',
  },
  {
    route: 'iso.merchants.index',
    icon: '$store-alt',
    text: 'Merchants',
  },
  {
    route: 'iso.shopping-carts',
    icon: '$shopping-cart',
    text: 'Shopping Carts',
    feature: Feature.SHOPPING_CARTS,
  },
  {
    route: 'iso.reports',
    icon: '$chart-line',
    text: 'Reports',
    children: [
      {
        route: 'iso.reports.residual',
        text: 'Residual Report',
      },
    ],
  },
  {
    href: 'https://docs.mypaymentnow.com/',
    icon: 'mdi-api',
    text: 'API Docs',
  },
];
