import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faArrowAltCircleUp,
  faArrowLeft,
  faAngleDown,
  faAngleUp,
  faCalendarAlt,
  faCreditCard,
  faChartLine,
  faCheckCircle,
  faCheckSquare,
  faExclamationCircle,
  faCog,
  faCoins,
  faDoorOpen,
  faEdit,
  faFileUpload,
  faFunnelDollar,
  faLifeRing,
  faMoneyBillAlt,
  faMoneyCheck,
  faPlusCircle,
  faRedo,
  faRocket,
  faSearch,
  faSignOutAlt,
  faSquare,
  faStoreAlt,
  faSync,
  faTachometerAlt,
  faTrash,
  faTimesCircle,
  faUserCircle,
  faUsers,
  faUserShield,
  faKey,
  faReceipt,
  faSave,
  faCopy,
  faFileInvoiceDollar,
  faStar,
  faTrashAlt,
  faWallet,
  faQuestionCircle,
  faInfoCircle,
  faSatelliteDish,
  faThumbtack,
} from '@fortawesome/free-solid-svg-icons';

import {
  faStar as farStar,
} from '@fortawesome/free-regular-svg-icons';

import {
  faCcVisa, faCcMastercard, faCcDiscover, faCcAmex,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faArrowAltCircleUp,
  faArrowLeft,
  faAngleDown,
  faAngleUp,
  faCalendarAlt,
  faCcAmex,
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
  faCreditCard,
  faChartLine,
  faCheckCircle,
  faCheckSquare,
  faExclamationCircle,
  faCog,
  faCoins,
  faDoorOpen,
  faEdit,
  faFileUpload,
  faFunnelDollar,
  faLifeRing,
  faMoneyBillAlt,
  faMoneyCheck,
  faPlusCircle,
  faRedo,
  faRocket,
  faSearch,
  faSignOutAlt,
  faSquare,
  faStoreAlt,
  faSync,
  faTachometerAlt,
  faTrash,
  faTimesCircle,
  faUserCircle,
  faUsers,
  faUserShield,
  faKey,
  faReceipt,
  faSave,
  faCopy,
  faFileInvoiceDollar,
  faStar,
  farStar,
  faTrashAlt,
  faWallet,
  faQuestionCircle,
  faInfoCircle,
  faSatelliteDish,
  faThumbtack,
);

export default FontAwesomeIcon;
