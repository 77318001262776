<template>
  <div v-if="shown">
    <div class="curtain" v-if="curtain" @click="curtainClicked"></div>
    <div class="modal">
      <a v-if="showCloseButton" href class="close-button" @click.prevent="hide">&times;</a>
      <div class="body">
        <slot></slot>
      </div>
      <div class="buttons" v-if="hasButtons">
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    curtain: {
      type: Boolean,
      default: true,
    },
    closeOnCurtainClick: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      shown: false as boolean,
    };
  },
  computed: {
    hasButtons(): boolean {
      return !!this.$slots.buttons;
    },
  },
  methods: {
    curtainClicked() {
      if (this.closeOnCurtainClick) {
        this.hide();
      }
    },
    show() {
      this.shown = true;
    },
    hide() {
      this.shown = false;
    },
    setVisibility(show: boolean) {
      this.shown = show;
    },
  },
});
</script>
