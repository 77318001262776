import axios from 'axios';
import getDomain from './domain';

export const headers = {
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
};

const instance = axios.create({
  baseURL: getDomain('api'),
  withCredentials: true,
  headers,
});

export function setHeader(name, value) {
  instance.defaults.headers.common[name] = value;
}

export function unsetHeader(name) {
  delete instance.defaults.headers.common[name];
}

let authenticated = false;

export function setFullyAuthenticated() {
  authenticated = true;
}

function isFullyAuthenticated() {
  const sleep = (ms, rejectOnTimeout = false) => new Promise(
    (resolve, reject) => setTimeout(rejectOnTimeout ? reject : resolve, ms),
  );

  let interval = null;

  const promise = new Promise((resolve) => {
    interval = setInterval(() => {
      if (authenticated) {
        clearInterval(interval);
        resolve();
      }
    }, 10);
  });

  const timeout = sleep(10000, true).catch((err) => {
    clearInterval(interval);
    throw err;
  });

  return Promise.race([promise, timeout]);
}

instance.interceptors.request.use((config) => {
  const skipAuthentication = config.skipAuthentication || false;

  if (skipAuthentication) {
    return config;
  }

  return isFullyAuthenticated().then(() => {
    const updated = {
      ...config,
      headers: {
        ...config.headers,
        'x-iso-proxy': instance.defaults.headers.common['x-iso-proxy'],
        'x-merchant-proxy': instance.defaults.headers.common['x-merchant-proxy'],
      },
    };

    return Promise.resolve(updated);
  });
});

export default instance;
