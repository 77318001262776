import { DataTimestamps, JsonApiData } from '@/jsonApi.types';
import { AchAccountHolderType, AchAccountType } from '@/common/types/payments.types';
import { MerchantFeature } from '@/common/types/features.types';
import { MerchantCardType } from '@/common/types/cardTypes.types';
import { initMerchantFeastures } from '@/common/util/features.util';
import { initMerchantCardTypes } from '@/common/util/cardTypes.util';
import { ConvenienceFee } from '@/common/types/convenienceFee.types';
import { NonCashAdjustmentFee } from '@/common/types/nonCashAdjustment.types';

export enum MerchantStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  RESTRICTED = 'restricted',
  CLOSED = 'closed',
  CREATED = 'created',
}

export enum ShoppingCartStatus {
  LIVE = 'live',
  CLOSED = 'closed',
}

export interface MerchantSettings {
  externalIdentifier: string | null;
  externalIdentifier2: string | null;
  timezone: string;
  mccCode: string;
  enableAvs: boolean;
  taxId: string;
  isoHidePricingSchedule: boolean;
  hidePricingSchedule: boolean;
}

export interface MerchantBanking {
  routingNumber: string;
  accountNumber: string;
  accountType: AchAccountType;
  accountHolderType: AchAccountHolderType;
}

export interface SellRate {
  group: string;
  name: string;
  key: string;
  value: number;
  buyRate: number | string;
}

export interface EmailInfo {
  username: string;
}

export interface Tag {
  id: string;
  uuid: string;
  name: string;
}

export interface Merchant extends JsonApiData, DataTimestamps {
  status: MerchantStatus;
  company: string;
  subdomain: string;
  username?: string;
  siteUrl: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  settings: MerchantSettings;
  banking: MerchantBanking;
  sellRates: SellRate[];
  features: MerchantFeature[];
  emailInfo: EmailInfo;
  surchargePercent: number;
  processorCount: number;
  cardTypes: MerchantCardType[];
  showKeybankAch: boolean;
  showHuntingtonAch: boolean;
  tags: Tag[];
}

export interface VirtualTerminalMerchant extends JsonApiData, DataTimestamps {
  surchargePercent: number;
  isoName: string;
  isoEmail: string;
  isoPhone: string;
  status: string;
  nonCashAdjustmentFee: NonCashAdjustmentFee,
  convenienceFee?: ConvenienceFee,
  timezone?: string;
  avsEnabled?: boolean;
}

export function initMerchant(): Merchant {
  return {
    status: MerchantStatus.PENDING,
    company: '',
    subdomain: '',
    username: '',
    siteUrl: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
    settings: {
      externalIdentifier: '',
      timezone: 'America/Chicago',
      mccCode: '',
      enableAvs: true,
      taxId: '',
      isoHidePricingSchedule: false,
      hidePricingSchedule: false,
      externalIdentifier2: '',
    },
    banking: {
      routingNumber: '',
      accountNumber: '',
      accountType: AchAccountType.CHECKING,
      accountHolderType: AchAccountHolderType.BUSINESS,
    },
    sellRates: [],
    features: initMerchantFeastures(),
    emailInfo: {
      username: '',
    },
    surchargePercent: 0,
    processorCount: 0,
    cardTypes: initMerchantCardTypes(),
    showKeybankAch: false,
    showHuntingtonAch: false,
    tags: [],
  };
}
