export default [
  {
    route: 'admin.dashboard',
    icon: '$tachometer-alt',
    text: 'Dashboard',
  },
  {
    route: 'admin.isos.index',
    icon: '$coins',
    text: 'Affiliates',
  },
  {
    route: 'admin.merchants.service',
    icon: '$store-alt',
    text: 'Service',
  },
  {
    route: 'admin.buy-rates',
    icon: '$funnel-dollar',
    text: 'Buy Rates',
  },
  {
    route: 'admin.reports',
    icon: '$chart-line',
    text: 'Reports',
    children: [
      {
        route: 'admin.reports.commission',
        text: 'Commission Report',
      },
    ],
  },
  {
    href: 'https://docs.mypaymentnow.com/',
    icon: 'mdi-api',
    text: 'API Docs',
  },
];
