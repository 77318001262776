import { setHeader } from '@/common/api';
import auth from '@/common/auth';

import {
  generateCodeChallenge, base64UrlEncode, randomString,
} from './oauth-helpers';

const stateKey = 'coregateway.oauth.state';
const verifierKey = 'coregateway.oauth.verifier';

export default class OAuth {
  constructor(clientId, redirectUri) {
    this.clientId = clientId;
    this.redirectUri = redirectUri;

    this.state = randomString(40);
    this.verifier = randomString(128);
    generateCodeChallenge(this.verifier).then((challenge) => {
      this.challenge = base64UrlEncode(challenge);
    });
  }

  start() {
    const params = new URLSearchParams({
      client_id: this.clientId,
      redirect_uri: this.redirectUri,
      response_type: 'code',
      scope: '',
      state: this.state,
      code_challenge: this.challenge,
      code_challenge_method: 'S256',
    }).toString();

    window.localStorage.setItem(stateKey, this.state);
    window.localStorage.setItem(verifierKey, this.verifier);

    return auth.get(`oauth/authorize?${params}`).then(
      ({ data }) => this.complete(data.code, data.state),
    );
  }

  complete(code, state) {
    return new Promise((resolve, reject) => {
      if (state !== window.localStorage.getItem(stateKey)) {
        reject(new Error('Invalid state'));
      }

      auth.post('oauth/token', {
        grant_type: 'authorization_code',
        client_id: this.clientId,
        redirect_uri: this.redirectUri,
        code_verifier: window.localStorage.getItem(verifierKey),
        code,
      }).then(({ data }) => {
        const token = data.access_token;
        setHeader('Authorization', `Bearer ${token}`);
        resolve(token);
      }).catch(reject);
    });
  }
}
