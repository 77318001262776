<template>
  <div class="container">
    <div class="form-wrapper">
      <Logo />
      <v-form @submit.prevent="attemptLogin" class="rounded-lg">
        <h2 style="text-align: center">Login</h2>
        <PageLoader ref="loader">
          <TextInput
            v-model="username"
            name="username"
            label="User name"
            placeholder="User name"
            :errors="validationErrors"
            :autofocus="true"
            required
            dense
          />
          <TextInput
            dense
            v-model="password"
            name="password"
            label="Password"
            placeholder="Password"
            :errors="validationErrors"
            :inputType="InputTypes.PASSWORD"
          />
          <v-card-actions>
            <v-col class="text-left pa-0">
              <router-link
                :to="{ name: `password-reset` }"
              >
              Forgot Password?
              </router-link>
            </v-col>

            <v-col class="text-right pa-0">
              <v-btn right color="success" class="mr-4" type="submit"> Login </v-btn>
            </v-col>
          </v-card-actions>
        </PageLoader>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import TextInput from '@/common/components/forms/TextInput.vue';
import Logo from '@/common/components/Logo.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import { mapGetters } from 'vuex';
import { ValidationErrors } from '@/common/validation/validation.types';
import { InputTypes } from '@/common/types/input.types';

export default Vue.extend({
  data() {
    return {
      title: 'Login' as string,
      username: '' as string,
      password: '' as string,
      validationErrors: {} as ValidationErrors,
      InputTypes,
    };
  },
  created() {
    if (this.title) {
      document.title = this.title;
    }
  },
  mounted() {
    (this.$refs as any).loader.setReady();
  },
  computed: {
    ...mapGetters(['context']),
    queryString(): string {
      return window.location.href.split('?')[1];
    },
  },
  methods: {
    attemptLogin() {
      (this.$refs as any).loader.setLoading();
      this.$store
        .dispatch('login', {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$router.push(this.$store.getters.redirect || { name: `${this.context}.dashboard` });
        })
        .catch(this.showLoginErrors);
    },
    showLoginErrors({ response: { data: response } }: { response: any }) {
      (this.$refs as any).loader.setReady();
      this.validationErrors = response?.errors || {};
    },
  },
  components: {
    Logo,
    PageLoader,
    TextInput,
  },
});
</script>
