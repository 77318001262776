<template>
  <div
    v-if="value"
    class="alert-box"
    :class="{
      'alert-info': alertType === AlertType.Info,
      'alert-warning': alertType === AlertType.Warning,
      'alert-error': alertType === AlertType.Error,
      'alert-success': alertType === AlertType.Success,
    }"
    role="alert"
  >
    <p class="tw-font-bold">{{ headerText }}</p>
    <p>{{ message }}</p>
    <span
      v-if="showToggle"
      class="alert-box-toggle"
      @click="toggleVisbility"
    >
      <svg
        class="alert-box-close"
        role="button"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <title>Close</title>
        <!-- eslint-disable-next-line max-len -->
        <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
      </svg>
    </span>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { AlertType } from './alerts.types';

export default Vue.extend({
  props: {
    alertType: {
      type: Number,
      required: false,
      default: AlertType.Info,
    },
    message: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    header: {
      type: String,
      required: false,
    },
    showToggle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      AlertType,
    };
  },
  computed: {
    headerText(): any {
      return this.header ?? AlertType[this.alertType];
    },
  },
  methods: {
    toggleVisbility() {
      this.$emit('input', !this.value);
    },
  },
  components: {
  },
});
</script>
