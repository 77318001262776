<template>
  <v-row justify="center">
    <v-dialog
      v-model="deleteModal"
      @keydown.esc="$emit('closeDeleteModal')"
      persistent
      max-width="700">
      <v-card justify="center" class="p-3">
        <v-card-title>
          Are you sure you want to {{modalAction}}
          {{ config.entityName ? config.entityName : 'subscription' }}:
          <strong> {{identifierProperty}} </strong>?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" @click="$emit('closeDeleteModal')">No </v-btn>
          <v-btn small color="secondary"
            @click="$emit('deleteEntity', eventToBeTriggered)">Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    config: {
      type: Object,
      required: true,
    },
    deleteModal: {
      type: Boolean,
    },
    entitySelectedForDeletion: {
      type: Object,
    },
    modalAction: {
      type: String,
    },
    eventToBeTriggered: {
      type: String,
      required: false,
    },
  },
  computed: {
    identifierProperty(): string {
      if (this.entitySelectedForDeletion) {
        if (this.entitySelectedForDeletion[this.config.identifierProperty]) {
          return this.entitySelectedForDeletion[this.config.identifierProperty];
        } return this.config.name;
      }
      return '';
    },
  },
});
</script>
