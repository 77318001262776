<template>
<div>
  <div :class="isMobile() ? 'card' : 'card tw-w-3/5'">
    <PageHeader tag="h3">
      API Tokens
      <template #right>
        <v-btn small color="secondary" @click="showAddTokenPopup">New</v-btn>
      </template>
    </PageHeader>
    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th class="actions"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="token in tokens" :key="token.id">
            <td>{{ token.name }}</td>
            <td class="actions">
              <a href class="danger" @click.prevent="confirmDeleteEntity(token)">revoke</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Modal ref="tokenRevealModal" :closeOnCurtainClick="false" width="800">
      <h3>API Token: {{ modalTokenName }}</h3>
      <p class="tw-text-red-400">
        Copy this API token for your records. This is the last time you will be able to view it.
      </p>
      <FormTextarea
        v-model="modalBearerToken"
        readonly
        rows="13"
        name="apiToken"
        :textStyle="{ backgroundColor: '#FFFFFF'
                      , outline: '0.5px solid black'
                      , height: 'auto'
                      , maxHeight: '35vh'
                    }"
      />
      <template #buttons v-if="canCopyToClipboard">
        <v-btn color="secondary" @click="copyToClipboard(modalBearerToken)">Copy API token</v-btn>
      </template>
    </Modal>
  </div>

  <v-dialog
    v-model="showModal"
    @keydown.esc="closeAddTokenPopup"
    persistent
    max-width="600"
  >
    <v-card justify="center" class="p-1">
      <v-card-title>API Token
        <v-spacer />
        <v-btn icon @click="closeAddTokenPopup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="createToken" ref="form" lazy-validation>
          <v-text-field
            name="name"
            label="Name your API token"
            v-model="tokenName"
            :rules="[(v) => !!v || 'API Token Name is required']"
            outlined
            dense
          />

          <v-btn color="secondary" type="submit" class="mt-2">Create</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
  <DeleteModal
    :deleteModal="deleteModal"
    :entitySelectedForDeletion="entitySelectedForDeletion"
    :config="config"
    @deleteEntity="deleteEntity"
    @closeDeleteModal="closeDeleteModal"
    modalAction="revoke"
  />
  <LoadingModal loadingText="Creating API Token..." :showModal="isSubmitting" />
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import api from '@/common/api';
import auth from '@/common/auth';
import FormTextarea from '@/common/components/forms/Textarea.vue';
import Modal from '@/common/components/Modal.vue';
import PageHeader from '@/common/components/PageHeader.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import DeleteModal from '@/common/components/modals/DeleteModal.vue';
import { ApiToken, ApiTokenScope } from '../support/support.types';

export default Vue.extend({
  mounted() {
    this.checkCanCopyToClipboard();
    this.loadTokens();
  },
  data() {
    return {
      canCopyToClipboard: false,
      tokens: [] as ApiToken[],
      tokenName: '' as string,
      modalTokenName: '' as string,
      modalBearerToken: '' as string,
      showModal: false,
      isSubmitting: false,
      entitySelectedForDeletion: {} as any,
      deleteModal: false,
      isDeleting: false,
      config: {
        pageTitle: 'API Tokens',
        apiPath: 'oauth/personal-access-tokens',
      },
    };
  },
  methods: {
    checkCanCopyToClipboard() {
      return (window.navigator as any).permissions
        .query({
          name: 'clipboard-write',
        })
        .then(() => {
          this.canCopyToClipboard = true;
        });
    },
    copyToClipboard(token: string) {
      navigator.clipboard.writeText(token);
      this.$toasted.success('Token copied to clipboard!');
    },
    loadTokens() {
      api
        .get('/tokens-for-user')
        .then(({ data: tokens }) => {
          this.tokens = tokens
            .filter((t: ApiToken) => !t.scopes.includes(ApiTokenScope.HPP_SUBMIT_PAYMENT_SCOPE))
            .map((t: ApiToken) => ({ hidden: true, ...t }));
        })
        .catch(() => {
          this.$toasted.error('Failed to load API tokens. Try again later.');
        });
    },
    createToken() {
      if (!this.isValid()) {
        return;
      }
      this.isSubmitting = true;
      auth
        .post('/oauth/personal-access-tokens', {
          name: this.tokenName,
          scopes: ['default'],
        })
        .then(
          ({
            data: {
              accessToken: bearer,
              token: { name },
            },
          }) => {
            this.isSubmitting = false;
            this.closeAddTokenPopup();
            this.revealToken(name, bearer);
            this.loadTokens();
            this.tokenName = '';
            (this.$refs.form as any).reset();
            this.$toasted.success('API token created successfully');
          },
        )
        .catch(() => {
          this.isSubmitting = false;
          this.$toasted.error('Could not create token. Please refresh the page & try again.');
        });
    },
    revealToken(name: string, bearer: string) {
      this.modalTokenName = name;
      this.modalBearerToken = bearer;
      (this.$refs.tokenRevealModal as any).show();
    },
    showAddTokenPopup() {
      this.showModal = true;
    },
    closeAddTokenPopup() {
      this.showModal = false;
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    isMobile(): boolean {
      if (this.$vuetify.breakpoint.width === 0) return false;

      return this.$vuetify.breakpoint.width < 600;
    },
    confirmDeleteEntity(entity: any) {
      this.entitySelectedForDeletion = entity;
      this.deleteModal = true;
    },
    deleteEntity() {
      this.isDeleting = true;
      this.deleteModal = false;
      api
        .delete(`${this.config.apiPath}/${this.entitySelectedForDeletion.id}`)
        .then(() => {
          this.loadTokens();
          this.$toasted.success('API token deleted successfully');
        })
        .catch(({ response }) => {
          const message = response?.data?.message || 'Failed to revoke. Try again.';
          this.$toasted.error(`${message} (${response.status})`);
        })
        .finally(() => {
          this.deleteModal = false;
          this.isDeleting = false;
        });
    },
    closeDeleteModal() {
      this.deleteModal = false;
    },
  },
  components: {
    FormTextarea,
    Modal,
    PageHeader,
    LoadingModal,
    DeleteModal,
  },
});
</script>
