<template>
  <v-dialog
    ref="loadingModal"
    v-model="showModal"
    persistent
    width="400"
  >
    <v-card
      color="secondary"
      dark
    >
      <v-card-text>
        {{ loadingText }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    loadingText: {
      type: String,
      required: false,
      default: 'Loading...',
    },
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
});
</script>
