<template>
  <div class="card">
    <PageHeader tag="h3">
      GPG Public Keys
    </PageHeader>

    <icon icon="key" />
    <span class="tw-ml-4">
      <span>
          <a :href="`${publicPath}keys/core-commerce.gpg`" download>Download</a>
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Icon from '@/common/icon';
import PageHeader from '@/common/components/PageHeader.vue';

export default Vue.extend({
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  components: {
    PageHeader,
    Icon,
  },
});
</script>
