const Dashboard = () => import(/* webpackChunkName: "admin" */ '../views/Dashboard.vue');
const IsosIndex = () => import(/* webpackChunkName: "admin" */ '../views/isos/IsoIndex.vue');
const IsosNew = () => import(/* webpackChunkName: "admin" */ '../views/isos/IsoNew.vue');
const IsosEdit = () => import(/* webpackChunkName: "admin" */ '../views/isos/IsoEdit.vue');
const IsosDocs = () => import(/* webpackChunkName: "admin" */ '../views/isos/Docs.vue');
const CommissionReport = () => import(/* webpackChunkName: "admin" */ '../views/reports/CommissionReport.vue');
const Settings = () => import(/* webpackChunkName: "admin" */ '../views/settings/Index.vue');
const UsersIndex = () => import(/* webpackChunkName: "admin" */ '../views/users/AdminUsers.vue');
const UsersNew = () => import(/* webpackChunkName: "admin" */ '../views/users/AdminUserNew.vue');
const UsersEdit = () => import(/* webpackChunkName: "admin" */ '../views/users/AdminUserEdit.vue');
const HelpfulVideos = () => import(/* webpackChunkName: "admin" */ '../views/settings/HelpfulVideos.vue');

const MerchantsService = () => import(/* webpackChunkName: "iso" */ '../views/merchants/MerchantService.vue');
const HelpfulVideoCategories = () => import(/* webpackChunkName: "admin" */ '../views/settings/HelpfulVideoCategories.vue');

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/isos',
    name: 'isos.index',
    component: IsosIndex,
  },
  {
    path: '/isos/new',
    name: 'isos.new',
    component: IsosNew,
  },
  {
    path: '/isos/:id',
    name: 'isos.edit',
    props: true,
    component: IsosEdit,
  },
  {
    path: '/isos/:id/docs',
    name: 'isos.docs',
    component: IsosDocs,
    props: true,
  },
  {
    path: '/users',
    name: 'users.index',
    component: UsersIndex,
  },
  {
    path: '/users/new',
    name: 'users.new',
    component: UsersNew,
    props: true,
  },
  {
    path: '/users/:id',
    name: 'users.edit',
    props: true,
    component: UsersEdit,
  },
  {
    path: '/reports',
    name: 'reports',
    redirect: { name: 'reports.commission' },
  },
  {
    path: '/reports/commission',
    name: 'reports.commission',
    component: CommissionReport,
  },
  {
    path: '/buy-rates',
    name: 'buy-rates',
    component: Settings,
  },
  {
    name: 'merchants.service',
    path: '/merchants/service',
    component: MerchantsService,
  },
  {
    path: '/helpful-videos',
    name: 'helpful-videos',
    component: HelpfulVideos,
  },
  {
    path: '/helpful-video-categories',
    name: 'helpful-video-categories',
    component: HelpfulVideoCategories,
  },
];
