import {
  IsoStatus,
} from '@/admin/views/isos/iso.types';
import {
  MerchantStatus,
} from '@/iso/views/merchants/merchants.types';
import api, {
  setHeader,
} from '@/common/api';
import auth from '@/common/auth';
import fetchBranding from '@/common/branding';
import getSubdomain from '@/common/context';
import OAuth from '@/common/oauth';
import {
  LOGIN_PATH,
} from '@/common/routes';

const redirectUri = () => `${window.location.origin}${LOGIN_PATH}`;

const oauth = new OAuth(process.env.VUE_APP_OAUTH_CLIENT_ID, redirectUri());

const contextPromise = getSubdomain() === 'admin'
  ? new Promise((resolve) => {
    resolve('admin');
  })
  : new Promise((resolve) => {
    api.get('context', {
      skipAuthentication: true,
    }).then(({
      data: context,
    }) => {
      resolve(context);
    });
  });

let authPromise = null;
const startAuth = ({
  commit,
  dispatch,
}) => {
  authPromise = new Promise((resolve) => {
    auth
      .get('/auth')
      .then(() => oauth.start())
      .then((token) => {
        commit('setAccessToken', token);
        Promise.all([
          dispatch('loadCurrentUser'),
          dispatch('loadFeatures'),
          dispatch('loadAvailableProcessors'),
        ]).then(() => {
          resolve(true);
        });
      })
      .catch(() => {
        commit('clearAccessToken');
        resolve(false);
      });
  });

  return authPromise;
};

const setProxyHeader = (context, role) => {
  const allowableContexts = role === 'admin' ? ['iso', 'merchant'] : ['merchant'];

  if (allowableContexts.includes(context)) {
    setHeader(`x-${context}-proxy`, getSubdomain());
  }
};

const redirectDisallowedContext = (context, user) => {
  const redirect = `${user.subdomain}${window.location.pathname}`;
  if (user.role === 'iso' && context === 'admin') {
    window.location.href = redirect;
  } else if (user.role === 'merchant' && context !== 'merchant') {
    window.location.href = redirect;
  }
};

const redirectOnboardingIso = (context, user) => {
  let redirect = user.subdomain;
  const currentPath = window.location.pathname;
  if (context === 'iso' && user.isoId && user.role === 'iso') {
    api.get('iso').then(({
      data,
    }) => {
      const iso = data.data;
      const isoStatus = iso.status;
      if (
        (isoStatus === IsoStatus.QUALIFIED || isoStatus === IsoStatus.SETUP)
        && currentPath !== '/onboarding/docs'
      ) {
        redirect += '/onboarding/docs';
        window.location.href = redirect;
      } else if (isoStatus === IsoStatus.CREATED && !currentPath.includes(
        '/onboarding/proposal',
      )) {
        redirect += `/onboarding/proposal/${iso.proposals[0].uuid}`;
        window.location.href = redirect;
      } else if (isoStatus === IsoStatus.SIGNED && currentPath !== '/onboarding/setup') {
        redirect += '/onboarding/setup';
        window.location.href = redirect;
      }
    });
  }
};

const redirectPendingMerchant = (context, user) => {
  let redirect = user.subdomain;
  const currentPath = window.location.pathname;
  if (context === 'merchant' && user.id && user.role === 'merchant') {
    api.get('merchant').then(({
      data,
    }) => {
      const merchant = data.data;
      const merchantStatus = merchant.status;
      if (
        merchantStatus === MerchantStatus.PENDING
        && currentPath !== '/onboarding/proposal'
      ) {
        redirect += '/onboarding/proposal';
        window.location.href = redirect;
      }
    });
  }
};

export default {
  init({
    dispatch,
  }) {
    dispatch('getContext');
    dispatch('authenticate');
    dispatch('fetchBranding');
  },
  getContext({
    commit,
  }) {
    return contextPromise.then((response) => {
      commit('setContext', response);
      return response;
    });
  },
  fetchBranding({
    commit,
  }) {
    fetchBranding(getSubdomain()).then((branding) => {
      commit('setBranding', branding);
      commit('setBrandName', branding.name);
      commit('setBrandCompany', branding.company);
      commit('setLogo', branding.logo);
    });
  },
  login(context, credentials) {
    authPromise = null;
    const updatedcredentials = {
      ...credentials,
      subdomain: getSubdomain(),
    };

    return auth.post('/login', updatedcredentials);
  },
  authenticate(context) {
    return authPromise === null ? startAuth(context) : authPromise;
  },
  clearAuthPromise({
    commit,
  }) {
    authPromise = null;
    commit('clearAccessToken');
  },
  loadAvailableProcessors({
    commit,
  }) {
    return api.get('available-processors').then(({
      data,
    }) => {
      commit('setAvailableProcessors', data.data);
    });
  },
  loadCurrentUser({
    commit,
    dispatch,
  }) {
    return Promise.all([
      dispatch('getContext'),
      api.get('user', {
        skipAuthentication: true,
      }).then(({
        data: {
          data: user,
        },
      }) => user),
    ]).then(([context, user]) => {
      setProxyHeader(context, user.role);
      redirectDisallowedContext(context, user);
      if (context === 'iso') {
        redirectOnboardingIso(context, user);
      } else if (context === 'merchant') {
        redirectPendingMerchant(context, user);
      }
      commit('setCurrentUser', user);
    });
  },
  logout({
    commit,
  }) {
    return auth.post('logout').then(() => {
      authPromise = null;
      commit('clearAccessToken');
    });
  },
  loadMerchantProcessors({
    commit,
  }, context) {
    if (context === 'merchant') {
      return api.get('processors').then(({
        data,
      }) => {
        commit('setProcessors', data.data);
      });
    }
    commit('setProcessors', []);
    return Promise.resolve([]);
  },
  loadFeatures({ commit }) {
    return api.get(`features/${getSubdomain()}`).then(({
      data,
    }) => {
      commit('setFeatures', data.data.features);
    });
  },
  loadVirtualTerminalMerchant({
    commit,
    state,
  }, context) {
    if (context === 'merchant') {
      if (state.virtualTerminal === null) {
        return api.get(`merchants/${getSubdomain()}/virtual-terminal`).then(({
          data,
        }) => {
          commit('setVirtualTerminal', data.data);
        });
      }
      return Promise.resolve(state.virtualTerminal); // cached result
    }
    return Promise.resolve({});
  },
  resetRecurrenceForm() {},
  loadHostedPaymentPage({ commit }, hppId) {
    return new Promise((resolve, reject) => {
      auth.get(`/payment-pages/${hppId}`, { skipAuthentication: true }).then(({
        data: { data },
      }) => {
        commit('setAccessToken', data.token);
        resolve(data);
      }).catch(reject);
    });
  },
};
