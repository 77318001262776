<template>
  <div>
    <v-dialog v-model="showModal" @keydown.esc="close" persistent max-width="1000">
      <v-card justify="center" class="p-1">
        <v-card-title>Add Webhook
          <v-spacer />
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="save" ref="form" lazy-validation>
            <v-container fluid>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="newWebhook.name"
                    :rules="[(v) => !!v || 'Field name is required']"
                    label="Name"
                    outlined
                    dense
                  />
                  <v-text-field
                    v-model="newWebhook.url"
                    :rules="[(v) => !!v || 'Field url is required']"
                    label="URL"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="6">
                  <v-textarea
                    placeholder="Add a description, such as intent (optional)"
                    name="description"
                    height="100"
                    auto-grow
                    v-model="newWebhook.description"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-expansion-panels class="mb-10">
              <v-expansion-panel class="mb-5">
                <v-expansion-panel-header>
                  Events
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col
                      v-for="(event, i) in transactionEvents" :key="i"
                      cols="4"
                    >
                      <v-checkbox
                        v-model="newWebhook.webhookEvents[i]"
                        outlined
                        dense
                        :label="event"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="mb-5">
                <v-expansion-panel-header>Sources</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col v-for="(source, i) in this.transactionSources" :key="i" cols="3">
                      <v-checkbox
                        v-model="newWebhook.webhookTransactionSources[i]"
                        outlined
                        dense
                        :label="source.toString().replaceAll('_', ' ').toUpperCase()"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>Hosted Payment Pages</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template>
                      <thead>
                        <tr>
                          <th>HPP Name</th>
                          <th>Status</th>
                          <th>Enable</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template>
                          <tr v-for="(hpp, i)  in this.hpps" :key="i">
                            <td>{{ hpp.name }}</td>
                            <td>{{ formatStatusText(hpp.pageStatus) }}</td>
                            <td>
                              <v-checkbox
                                v-model="newWebhook.hostedPaymentPages[hpp.id]"
                                outlined
                                dense
                                label=""
                              />
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-btn color="secondary" type="submit">Save</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <LoadingModal loadingText="Loading ..." :showModal="isSubmitting" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import api from '@/common/api';
import { toDisplayText } from '@/common/util/hostedPaymentPages.util';
import LoadingModal from '@/common/components/LoadingModal.vue';
import {
  initWebhook,
  Webhook,
  TransactionSources,
  TransactionEvents,
} from '@/common/components/support/support.types';
import {
  HostedPaymentPageStatus, HostedPaymentPageStatusDisplay,
} from '@/common/types/hostedPaymentPages.types';

export default Vue.extend({
  props: {
    hpps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newWebhook: initWebhook() as Webhook,
      valid: true,
      isSubmitting: false as boolean,
      showModal: false as boolean,
      transactionEvents: [''] as any,
      transactionSources: [''] as any,
    };
  },
  mounted() {
    this.assignData();
  },
  methods: {
    assignData() {
      this.transactionEvents = TransactionEvents;
      this.transactionSources = TransactionSources;
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    save() {
      if (!this.isValid()) {
        return;
      }
      api
        .post('/webhooks', {
          name: this.newWebhook.name,
          url: this.newWebhook.url,
          description: this.newWebhook.description,
          transactionEvents: this.newWebhook.webhookEvents,
          transactionSources: this.newWebhook.webhookTransactionSources,
          hpps: this.newWebhook.hostedPaymentPages,
        })
        .then(() => {
          this.newWebhook = initWebhook();
          this.$emit('created');
          this.$toasted.success('Webhook created successfully!');
          (this.$refs.form as any).resetValidation();
          this.close();
        })
        .catch(({ response }: { response: any }) => {
          if (typeof response.data !== 'undefined' && typeof response.data.errors !== 'undefined') {
            const errorMessage: any[] = [];
            Object.keys(response.data.errors).forEach((key: string) => {
              errorMessage.push(response.data.errors[key]);
            });
            const message = errorMessage.join('<br />');
            this.$toasted.error(
              message,
            );
          }
        });
    },
    formatStatusText(status: HostedPaymentPageStatus) : HostedPaymentPageStatusDisplay {
      return toDisplayText(status);
    },
    show() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  components: {
    LoadingModal,
  },
});

</script>
