import { SettingsRow } from '@/common/types/settings.types';

const adminSettings: SettingsRow[] = [
  {
    columns: [
      {
        title: 'Settings',
        settings: [
          {
            title: 'Users',
            route: 'admin.users.index',
            icon: '$users',
          },
          {
            title: 'API Tokens',
            route: 'settings.api-tokens',
            icon: 'mdi-api',
          },
          {
            title: 'Helpful Videos',
            route: 'admin.helpful-videos',
            icon: 'mdi-video',
          },
          {
            title: 'Helpful Video Categories',
            route: 'admin.helpful-video-categories',
            icon: 'mdi-video',
          },
        ],
      },
    ],
  },

];

export default adminSettings;
