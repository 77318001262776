import Permissions from '@/common/permissions';
import { Feature } from '@/features.types';

export default [
  {
    route: 'merchant.dashboard',
    icon: '$tachometer-alt',
    text: 'Dashboard',
  },
  {
    route: 'merchant.virtual-terminal',
    icon: '$credit-card',
    text: 'Virtual Terminal',
  },
  {
    route: 'merchant.hosted-payments',
    icon: '$receipt',
    text: 'Hosted Payment Page',
    feature: Feature.HPP,
    permission: Permissions.MANAGE_HOSTED_PAYMENT_PAGES,
  },
  {
    route: 'merchant.shopping-carts',
    icon: '$shopping-cart',
    text: 'Shopping Carts',
    feature: Feature.SHOPPING_CARTS,
  },
  {
    route: 'merchant.plans.index',
    icon: '$calendar-alt',
    text: 'Plans',
  },
  {
    route: 'merchant.customers.index',
    icon: '$user-shield',
    text: 'Customer Vault',
    permission: Permissions.MANAGE_CUSTOMER_VAULT,
    children: [
      {
        route: 'merchant.customers.index',
        text: 'Customer Vault',
      },
      {
        route: 'merchant.customers.batch.uploads.index',
        text: 'Batch Imports',
      },
    ],
  },
  {
    icon: '$box',
    text: 'Product Manager',
    feature: Feature.INVOICING,
    children: [
      {
        route: 'merchant.products.index',
        text: 'Products',
      },
      {
        text: 'Categories',
        route: 'merchant.categories.index',
      },
    ],
  },
  {
    icon: '$invoice',
    route: 'merchant.invoices.index',
    feature: Feature.INVOICING,
    permission: Permissions.MANAGE_INVOICING,
    text: 'Invoices',
  },
  {
    route: 'merchant.reports',
    icon: '$chart-line',
    text: 'Reports',
    permission: Permissions.VIEW_MERCHANT_REPORTS,
    children: [
      {
        route: 'merchant.reports.transactions.details',
        text: 'Transaction Details',
      },
      {
        route: 'merchant.reports.batch.settlements',
        text: 'Batch Settlements',
      },
      {
        route: 'merchant.reports.recurring',
        text: 'Subscription',
      },
      {
        route: 'merchant.reports.billing.details',
        text: 'Billing',
      },
    ],
  },
  {
    href: 'https://docs.mypaymentnow.com/',
    icon: 'mdi-api',
    text: 'API Docs',
  },
];
