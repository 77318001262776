<template>
  <v-app-bar app clipped-left color="primary">
    <router-link to="/">
      <v-img class="mx-2" max-height="40" max-width="100" contain :src="this.logo" />
    </router-link>

    <v-toolbar-title class="tw-text-brand-primary-text">{{ brandCompany }}</v-toolbar-title>

    <v-spacer />

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="accent" small v-bind="attrs" v-on="on"
          >{{ currentUserDisplayName }} <v-icon right>$chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in menuItems" :key="index" link :to="{ name: item.to }">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item v-if="context !== 'admin'" link :to="{ path: '/helpful-videos' }">
          <v-list-item-title>Helpful Videos</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-video</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
          <v-list-item-icon>
            <v-icon v-text="'$sign-out-alt'" />
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script lang="ts">
import Vue from 'vue';

import { mapGetters } from 'vuex';

interface MenuItem {
  icon: string;
  title: string;
  to: string;
}

export default Vue.extend({
  computed: {
    ...mapGetters(['logo', 'brandCompany', 'currentUserDisplayName', 'context']),
    menuItems(): MenuItem[] {
      return [
        { title: 'Settings', icon: '$cog', to: 'settings' },
        { title: 'Support', icon: '$life-ring', to: 'support' },
      ];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push({ name: 'login' });
      });
    },
  },
});
</script>
