<template>
  <div class="container">
    <div class="form-wrapper">
      <logo />
      <form @submit.prevent="attemptReset">
        <div v-if="isSuccess" class="alert alert-success">
          {{ successMessage }}
        </div>
        <v-text-field
          type="password"
          name="password"
          label="Password"
          v-model="password"
          :error-messages="validationErrors.password"
          outlined
          dense
        />
        <v-text-field
          type="password"
          name="confirm"
          label="Confirm password"
          v-model="confirm"
          :error-messages="validationErrors.confirm"
          outlined
          dense
        />
        <div class="buttons">
          <v-btn color="secondary" type="submit">Set password</v-btn>
        </div>
      </form>
    </div>
    <LoadingModal loadingText="Updating Password..." :showModal="isSubmitting" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import auth from '@/common/auth';
import Logo from '@/common/components/Logo.vue';
import { initValidationErrors } from '@/common/validation/validation.types';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import LoadingModal from '@/common/components/LoadingModal.vue';

export default Vue.extend({
  data() {
    return {
      title: 'Set your new password' as string,
      password: '' as string,
      confirm: '' as string,
      redirect: '/' as string,
      isSuccess: false as boolean,
      successMessage: '' as string,
      validationErrors: initValidationErrors(),
      isSubmitting: false,
    };
  },
  created() {
    if (this.title) {
      document.title = this.title;
    }
  },
  computed: {
    queryString() {
      return window.location.href.split('?')[1];
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(this.queryString);
    if (urlParams.get('redirect')) {
      this.redirect = `/${urlParams.get('redirect')}`;
    }
  },
  methods: {
    attemptReset() {
      this.isSubmitting = true;
      auth
        .post('password/reset', {
          password: this.password,
          password_confirmation: this.confirm,
          token: this.$route.params.token,
        })
        .then(this.showSuccess)
        .catch(this.showError);
    },
    showSuccess({ data }: { data: JsonApiSingleResponse<any> }) {
      this.isSubmitting = false;
      this.successMessage = `${data.message}. You will be redirected in 3 seconds.`;
      this.isSuccess = true;
      this.$store.dispatch('clearAuthPromise');
      setTimeout(() => this.$router.push(this.redirect), 3000);
    },
    showError({ response: { data: response } }: { response: any }) {
      this.isSubmitting = false;
      this.validationErrors = response?.errors;
    },
  },
  components: {
    Logo,
    LoadingModal,
  },
});
</script>
