import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/common/routes';
import store from '@/common/store';
import { PAY_HPP_ROUTE_NAME, PAY_INVOICE_ROUTE_NAME } from '@/payments/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  linkActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const publicRoutes = [
  'login', 'password-reset', 'confirm-password-reset',
];

const unauthenticatedRoutes = [
  PAY_HPP_ROUTE_NAME,
  PAY_INVOICE_ROUTE_NAME,
];

router.beforeEach((to, from, next) => {
  const isUnauthenticatedRoute = unauthenticatedRoutes.includes(to.name);
  if (isUnauthenticatedRoute) {
    next();
    return;
  }

  const requiresAuth = !publicRoutes.includes(to.name);
  Promise.all([store.dispatch('getContext'), store.dispatch('authenticate')])
    .then(([context, authenticated]) => {
      const routeContext = to.name?.split('.')[0];
      const isValidContext = ['admin', 'iso', 'merchant'].includes(routeContext);
      const contextsMatch = routeContext === context;
      const wrongContext = isValidContext && !contextsMatch;
      if (wrongContext) {
        const redirect = { name: to.name.replace(routeContext, context) };
        if (router.resolve(redirect).route.matched.length === 0) {
          redirect.name = `${context}.dashboard`;
        }
        next(redirect);
        return;
      }

      const redirectGuest = !authenticated && requiresAuth;
      const redirectUser = authenticated && !requiresAuth;

      if (redirectGuest) {
        store.commit('setRedirect', to.path);
        next({ name: 'login' });
      } else if (redirectUser) {
        next({ name: `${context}.dashboard` });
      } else {
        if (authenticated && store.getters.currentUser) {
          if (to.meta.permission && to.meta.permission.length > 0) {
            const { permission } = to.meta;
            if (!store.getters.currentUserCan(permission)) {
              const destination = { path: 'dashboard' };
              if (from.name === `${context}.dashboard`) {
                Object.assign(destination, { query: { r: Date.now() } });
              }
              next(destination);
            }
          }
        }
        next();
      }
    }).catch(console.error);
});

export default router;
