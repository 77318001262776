<template>
  <div class="tw-flex tw-flex-row">
    <div
      class="tw-px-4 tw-border-gray-400"
    >
      <h1>Security Keys</h1>
      <GpgPublicKeys />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import GpgPublicKeys from '@/common/components/settings/GpgPublicKeys.vue';

export default Vue.extend({
  components: {
    GpgPublicKeys,
  },
});
</script>
