import { Feature } from '@/features.types';

const Dashboard = () => import(/* webpackChunkName: "iso" */ '../views/Dashboard.vue');
const Proposal = () => import(/* webpackChunkName: "onboarding" */ '../views/Proposal.vue');
const SetupIsoInfo = () => import(/* webpackChunkName: "onboarding" */ '../views/onboarding/SetupIsoInfo.vue');
const SupportingDocuments = () => import(/* webpackChunkName: "onboarding" */ '../views/onboarding/SupportingDocuments.vue');
const MerchantsIndex = () => import(/* webpackChunkName: "iso" */ '../views/merchants/MerchantIndex.vue');
const MerchantsNew = () => import(/* webpackChunkName: "iso" */ '../views/merchants/MerchantNew.vue');
const MerchantsEdit = () => import(/* webpackChunkName: "iso" */ '../views/merchants/MerchantEdit.vue');
const ReportsMerchant = () => import(/* webpackChunkName: "iso" */ '../views/reports/Merchant.vue');
const ReportsResidual = () => import(/* webpackChunkName: "iso" */ '../views/reports/Residual.vue');
const UsersIndex = () => import(/* webpackChunkName: "iso" */ '../views/users/IsoUsers.vue');
const UsersNew = () => import(/* webpackChunkName: "iso" */ '../views/users/IsoUserNew.vue');
const UsersEdit = () => import(/* webpackChunkName: "iso" */ '../views/users/IsoUserEdit.vue');
const ShoppingCarts = () => import(/* webpackChunkName: "iso"  */ '../views/shopping-carts/ShoppingCarts.vue');
const SupportSettings = () => import(/* webpackChunkName: "iso" */ '../views/support/Settings.vue');
const HelpfulVideos = () => import(/* webpackChunkName: "merchant" */ '../../common/components/support/HelpfulVideos.vue');
const SettingTags = () => import(/* webpackChunkName: "iso" */ '../views/settings/IsoSettingTags.vue');


export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/onboarding/proposal/:id',
    name: 'proposal',
    component: Proposal,
    props: true,
  },
  {
    path: '/onboarding/docs',
    name: 'docs',
    component: SupportingDocuments,
  },
  {
    path: '/onboarding/setup',
    name: 'setup',
    component: SetupIsoInfo,
  },
  {
    name: 'merchants.index',
    path: '/merchants',
    component: MerchantsIndex,
  },
  {
    name: 'merchants.new',
    path: '/merchants/new',
    component: MerchantsNew,
  },
  {
    name: 'merchants.edit',
    path: '/merchants/:id',
    component: MerchantsEdit,
    props: true,
  },
  {
    path: '/reports',
    name: 'reports',
    redirect: { name: 'reports.merchant' },
  },
  {
    path: '/reports/merchant',
    name: 'reports.merchant',
    component: ReportsMerchant,
  },
  {
    path: '/reports/residual',
    name: 'reports.residual',
    component: ReportsResidual,
  },
  {
    path: '/users',
    name: 'users.index',
    component: UsersIndex,
  },
  {
    path: '/users/new',
    name: 'users.new',
    component: UsersNew,
    props: true,
  },
  {
    path: '/users/:id',
    name: 'users.edit',
    component: UsersEdit,
    props: true,
  },
  {
    path: '/shopping-carts',
    name: 'shopping-carts',
    component: ShoppingCarts,
    meta: {
      featureFlipping: { key: Feature.SHOPPING_CARTS, defaut: false },
    },
  },
  {
    path: '/support/settings',
    name: 'support.settings',
    component: SupportSettings,
  },
  {
    path: '/settings/tags',
    name: 'settings.tags',
    component: SettingTags,
  },
  {
    path: '/helpful-videos',
    name: 'helpful-videos',
    component: HelpfulVideos,
  },
];
