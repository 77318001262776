<template>
  <v-app  :class="[context, { guest: !authenticated }]">
    <Header v-if="showBars" />
    <Navbar v-if="showBars" @routerViewReload="reload" />
    <BrandTheme />
    <v-main>
      <v-container fluid>
        <router-view
          v-if="context"
          :key="this.$route.fullPath+linkIndex"
        />
        <Spinner fullscreen v-else />
        <IdleModal
          v-if="isIdle"
        />
      </v-container>
    </v-main>
    <Footer v-if="showBars" />
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';

import { mapGetters } from 'vuex';
import Navbar from '@/common/components/Navbar.vue';
import Spinner from '@/common/components/LoadingSpinner.vue';
import IdleModal from '@/common/components/modals/IdleModal.vue';
import { PAY_HPP_ROUTE_NAME, PAY_INVOICE_ROUTE_NAME } from './payments/routes';
import { HPP_PAYMENT_PAGES_NEW_ROUTE_NAME, HPP_PAYMENT_PREVIEW_ROUTE_NAME } from './merchant/routes';
import BrandTheme from './common/components/BrandTheme.vue';
import Header from './common/components/Header.vue';
import Footer from './common/components/Footer.vue';

export default Vue.extend({
  data() {
    return {
      linkIndex: 0 as number,
    };
  },
  computed: {
    ...mapGetters(['authenticated', 'context', 'isAdminContext']),
    isHppOrInvoice(): boolean {
      return this.$route.name === PAY_HPP_ROUTE_NAME
        || this.$route.name === HPP_PAYMENT_PREVIEW_ROUTE_NAME
        || this.$route.name === HPP_PAYMENT_PAGES_NEW_ROUTE_NAME
        || this.$route.name === PAY_INVOICE_ROUTE_NAME;
    },
    showBars(): boolean {
      return this.authenticated && !this.isHppOrInvoice;
    },
    isIdle(): boolean {
      return this.authenticated
      && this.$store.state.idleVue !== undefined
      && this.$store.state.idleVue.isIdle
      && !this.isAdminContext;
    },
  },
  methods: {
    reload() {
      this.linkIndex += 1;
    },
  },
  components: {
    Navbar,
    Spinner,
    IdleModal,
    Footer,
    Header,
    BrandTheme,
  },
});
</script>
