<template>
  <div class="container">
    <div class="form-wrapper">
      <logo />
      <form @submit.prevent="attemptReset">
        <div v-if="isSuccess" class="alert alert-success">Check your email!</div>
        <form-input
          name="username"
          label="User Name"
          v-model="username"
          :errors="validationErrors"
          @input="resetResult"
        ></form-input>
        <div class="buttons mt-5">
          <v-col class="text-right pa-0">
            <router-link
              :to="{ name: `login` }"
            >
            Back to Login
            </router-link>
          </v-col>
          <v-col class="text-right pa-0">
          <v-btn color="secondary" type="submit">Send password reset link</v-btn>
          </v-col>
        </div>
      </form>
    </div>
    <LoadingModal loadingText="Validating Username..." :showModal="isSubmitting" />
  </div>
</template>
<style scoped>
@media only screen and (max-width:575px){
.v-btn:not(.v-btn--round).v-size--default{margin-top:10px;}
.buttons {display: block;}
}
</style>
<script>
import auth from '@/common/auth';
import FormInput from '@/common/components/forms/Input.vue';
import Logo from '@/common/components/Logo.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';

const RESULT_NONE = 0;
const RESULT_SUCCESS = 1;

export default {
  title: 'Reset your password',
  data() {
    return {
      result: RESULT_NONE,
      username: '',
      validationErrors: {},
      isSubmitting: false,
    };
  },
  computed: {
    isSuccess() {
      return this.result === RESULT_SUCCESS;
    },
  },
  methods: {
    attemptReset() {
      this.isSubmitting = true;
      this.resetResult();
      auth
        .post('password/email', {
          username: this.username,
        })
        .then(this.showSuccess)
        .catch(this.showError);
    },
    showSuccess() {
      this.isSubmitting = false;
      this.result = RESULT_SUCCESS;
    },
    showError({ response: { data: response } }) {
      this.isSubmitting = false;
      this.validationErrors = response?.errors;
    },
    resetResult() {
      this.result = RESULT_NONE;
    },
  },
  components: {
    FormInput,
    Logo,
    LoadingModal,
  },
};
</script>
