<template>
  <v-container>
    <v-card
      class="mx-auto d-flex justify-center flex-column align-center ma-16"
      max-width="350"
      outline
      elevation="2"
      min-height="350"
    >
      <v-card-title class="justify-center">Support</v-card-title>
      <PageLoader ref="supportLoader">
        <v-card-text class="pt-16">
          <p>For support, please contact us!</p>
          <p>
            Email:
            <a class="link-text" :href="`mailto:${supportInfo.email}`">{{ supportInfo.email }}</a>
          </p>
          <p v-if="!isMerchantContext || (isMerchantContext && showPhone)">
            Phone:
            <a class="link-text" :href="`tel:${supportInfo.phone}`">
              {{ supportInfo.phone }}
            </a>
          </p>
          <div v-if="isMerchantContext && supportHtml" v-html="supportHtml" />
        </v-card-text>
      </PageLoader>
    </v-card>
    <v-card
      v-if="isIsoContext || isMerchantContext"
      class="mx-auto d-flex justify-center flex-column align-center ma-16"
      max-width="350"
      outline
      elevation="2"
      min-height="350"
    >
      <v-card-title class="justify-center"> Agreements </v-card-title>
      <PageLoader ref="documentLoader">
        <v-row v-if="signedAgreement.name && signedAgreement.status === 'uploaded'">
          <v-col>
            <v-btn
              color="secondary" :href="`${signedAgreement.url}`"
              target="_blank"
            >
              Download {{ signedAgreement.name }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isMerchantContext && !hidePricingSchedule">
          <v-col class="justify-center d-flex">
            <v-btn
              @click="showPricingSchedule"
            > Pricing Schedule
            </v-btn>
          </v-col>
        </v-row>
      </PageLoader>
    </v-card>
    <DocumentViewer
      ref="documentViewer"
      :html="docHtml"
      @closeRatesModal="closeRatesModal"
      supportPage
    />
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import api, { setHeader } from '@/common/api';
import PageLoader from '@/common/components/PageLoader.vue';
import { JsonApiArrayResponse, JsonApiSingleResponse } from '@/jsonApi.types';
import DocumentViewer from '@/common/components/DocumentViewer.vue';
import { SupportInfo, DocumentInfo } from '../support/support.types';

export default Vue.extend({
  data() {
    return {
      title: 'Support' as string,
      supportInfo: {
        id: '',
        email: '',
        phone: '',
      } as SupportInfo,
      signedAgreement: {} as DocumentInfo,
      docHtml: '' as string,
      showPhone: true as boolean,
      supportHtml: '' as string,
      hidePricingSchedule: false as boolean,
    };
  },
  mounted() {
    this.loadSupportInfo();
  },
  created() {
    if (this.title) {
      document.title = this.title;
    }
  },
  computed: {
    ...mapGetters(['isIsoContext', 'isMerchantContext']),
  },
  methods: {
    loadIsoAgreementDocuments(): void {
      const params: any = {
        name: 'Signed Agreement',
      };
      api
        .get('documents', {
          params,
        })
        .then(this.setDocuments)
        .catch(() => this.handleLoadingError('Agreements not found'));
    },
    loadMerchantAgreementDocuments(): void {
      const params: any = {
        name: 'Signed Agreement',
      };
      api
        .get('merchant/documents', {
          params,
        })
        .then(this.setDocuments)
        .catch(() => this.handleLoadingError('Agreements not found'));
    },
    setDocuments({ data }: { data: JsonApiArrayResponse<DocumentInfo> }) {
      const documents = data.data;
      if (documents) {
        const signedAgreements = documents.filter(
          (document: DocumentInfo) => document.name === 'Signed Agreement',
        );
        if (signedAgreements.length > 0) {
          const [first] = signedAgreements;
          this.signedAgreement = first;
        }
      }
      (this.$refs.documentLoader as any).setReady();
    },
    loadSupportInfo(): void {
      api
        .get('support-info')
        .then(this.setSupportInfo)
        .catch(() => this.handleLoadingError('Support info not found'));
    },
    setSupportInfo({ data }: { data: JsonApiSingleResponse<SupportInfo> }) {
      this.supportInfo = { ...data.data! };
      if (this.isIsoContext) {
        this.loadIsoAgreementDocuments();
      } else if (this.isMerchantContext) {
        this.loadIsoSupportSettingsForMerchant();
      }
      (this.$refs.supportLoader as any).setReady();
    },
    handleLoadingError(msg: string) {
      (this.$refs.documentLoader as any).setReady();
      this.$toasted.error(msg);
    },
    closeRatesModal() {
      (this.$refs.documentViewer as any).hide();
    },
    showPricingSchedule() {
      const params: any = {
        merchantId: this.supportInfo.id,
      };
      setHeader('Accept', 'text/html');
      setHeader('Accept', 'application/json');
      api
        .get('/support-info/fees', {
          params,
        })
        .then((response) => {
          this.docHtml = response.data;
          (this.$refs.documentViewer as any).show();
        });
    },
    loadIsoSupportSettingsForMerchant() {
      api
        .get('/iso-support-settings-for-merchant')
        .then(({ data }) => {
          this.showPhone = !!data.data.showPhone;
          this.supportHtml = data.data.supportHtml;
          this.supportInfo.id = data.data.id;
          this.hidePricingSchedule = data.data.hidePricingSchedule;
          this.loadMerchantAgreementDocuments();
        })
        .catch(() => {
          this.showPhone = true;
          this.supportHtml = '';
          this.hidePricingSchedule = false;
        });
    },
  },
  components: {
    PageLoader,
    DocumentViewer,
  },
});
</script>
