<template>
  <v-footer
    inset
    padless
    width="auto"
    class="mt-4"
    color="transparent"
  >
    <v-col class="text-center" >
      &#169; <strong>{{ company }}</strong> {{ new Date().getFullYear() }}
    </v-col>
  </v-footer>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapGetters(['isIsoContext', 'isMerchantContext', 'brandName']),
    company(): string {
      return (this.isIsoContext || this.isMerchantContext) ? this.brandName : 'CoreCommerce';
    },
    year(): number {
      const now = new Date();
      return now.getFullYear();
    },
  },
  components: {
  },
});
</script>
