import { Feature } from '@/features.types';

const PaymentPage = () => import(/* webpackChunkName: "payments"  */ '../views/payment-pages/PaymentPage.vue');
// eslint-disable-next-line max-len
const InvoicePaymentPage = () => import(/* webpackChunkName: "payments"  */ '@/merchant/views/invoices/InvoicePaymentPage.vue');


export const PAY_HPP_ROUTE_NAME = 'pay.hpp';
export const PAY_INVOICE_ROUTE_NAME = 'invoices.pay';

export default [
  {
    path: '/pay/:id',
    name: PAY_HPP_ROUTE_NAME,
    component: PaymentPage,
    props: true,
    meta: {
      featureFlipping: { key: Feature.HPP },
    },
  },
  {
    path: '/invoices/:id/pay/:calledFrom?',
    name: PAY_INVOICE_ROUTE_NAME,
    component: InvoicePaymentPage,
    props: true,
  },
];
