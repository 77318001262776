<template>
  <div v-if="currentUserCanManageSettings" class="tw-px-2">
    <v-container fluid>
        <v-row v-if="context === 'merchant'">
          <v-col>
            <h2>Settings</h2>
          </v-col>
        </v-row>
        <v-row v-for="(row, rowIndex) in settingRows" :key="rowIndex">
          <v-col v-for="(group, colIndex) in row.columns" :key="colIndex" cols="4">
            <v-card
              class="mx-auto"
              max-width="400"
              outlined
              elevation="2">
              <v-card-title class="justify-center">
                {{group.title}}
              </v-card-title>
              <v-list class="pl-4">
                <v-list-item v-for="(child, index) in group.settings"
                  :key="index"
                  :to="{ name: child.route }"
                  v-show="showLink(child)">
                  <v-list-item-icon v-if="showLink(child)">
                      <v-icon size="25">{{ child.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title v-if="showLink(child)" v-text="child.title" />
                  <v-tooltip v-if="child.help" top maxWidth="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>{{ child.help }}</span>
                  </v-tooltip>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
    </v-container>
  </div>
  <Alert v-else
    v-model="showAlert"
    :alertType="AlertType.Error"
    header="Access Denied"
    message="You don't have sufficient permissions to manage Settings."
    :showToggle="false"
  />
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Feature } from '@/features.types';
import adminSettings from '@/admin/settings/admin-settings';
import isoSettings from '@/iso/settings/iso-settings';
import merchantSettings from '@/merchant/settings/merchant-settings';
import { isEnabled } from 'vue-feature-flipping';
import Permissions from '@/common/permissions';
import { AlertType } from '@/common/components/alerts.types';
import Alert from '@/common/components/Alert.vue';

export default Vue.extend({
  created() {
    if (this.title) {
      document.title = this.title;
    }
  },
  data() {
    return {
      Feature,
      title: 'Settings' as string,
      AlertType,
      showAlert: true as boolean,
    };
  },
  computed: {
    ...mapGetters([
      'context',
      'isEnhancedDataEnabled',
      'isNonCashAdjustmentEnabled',
      'isConvenienceFeeEnabled',
      'currentUser',
      'currentUserCan',
    ]),
    settingRows(): any[] {
      if (this.context === 'admin') {
        return adminSettings;
      }
      if (this.context === 'iso') {
        return isoSettings;
      }
      if (this.context === 'merchant') {
        return merchantSettings;
      }
      return [];
    },
    // only show webhook button if the current user has the manage-webhooks user permission enabled
    // or the current user is an admin
    isWebhookUserPermissionEnabled(): boolean {
      return this.currentUser?.permissions.find((permission: string) => permission === Permissions.MANAGE_WEBHOOKS)
      || this.currentUser?.role === 'admin';
    },
    currentUserCanManageSettings(): boolean {
      return ['admin', 'iso'].includes(this.currentUser?.role) || this.currentUserCan(Permissions.MANAGE_SETTINGS);
    },
  },
  methods: {
    showLink(child:any): boolean {
      if (this.context === 'merchant' && child.feature === Feature.LEVEL23) {
        return this.isEnhancedDataEnabled;
      }

      if (this.context === 'merchant' && child.feature === Feature.NON_CASH_ADJUSTMENT) {
        return this.isNonCashAdjustmentEnabled;
      }

      if (this.context === 'merchant' && child.feature === Feature.CONVENIENCE_FEE) {
        return this.isConvenienceFeeEnabled;
      }

      if (this.context === 'merchant' && child.title === 'Webhooks') {
        return this.isWebhookUserPermissionEnabled;
      }

      return child.feature === undefined || isEnabled(child.feature);
    },
  },
  components: {
    Alert,
  },
});
</script>
<style scoped>
.v-tooltip__content {
  opacity: 1 !important;
}
</style>
