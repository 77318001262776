import { SettingsRow } from '@/common/types/settings.types';

const isoSettings: SettingsRow[] = [
  {
    columns: [
      {
        title: 'Settings',
        settings: [
          {
            title: 'Users',
            route: 'iso.users.index',
            icon: '$users',
          },
          {
            title: 'Support Settings',
            route: 'iso.support.settings',
            icon: '$life-ring',
          },
          {
            title: 'Tags',
            route: 'iso.settings.tags',
            icon: '$tags',
          },
        ],
      },
    ],
  },
];

export default isoSettings;
