import axios from 'axios';
import getDomain from './domain';


const headers = {
  common: {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

const instance = axios.create({
  baseURL: getDomain('api'),
  withCredentials: true,
  withXSRFToken: true,
  headers,
});

export default instance;
