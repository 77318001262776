<template>
  <v-navigation-drawer
    app
    permanent
    clipped
    :key="isPinned"
    :expand-on-hover="!isPinned"
    light
    :mini-variant.sync="mini"
    v-model="drawer"
  >
    <v-list nav dense>
      <div
        v-for="(item, index) in allowedItems" :key="index"
      >
        <v-list-group
          color="black"
          v-if="navItemHasChildren(item)"
          :prepend-icon="item.icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </template>

          <v-list-item
            v-for="(child, childIdx) in item.children" :key="childIdx"
            :to="{ name: child.route }"
            @mousedown.native="mousedown(child)"
          >
            <v-list-item-title>{{ child.text }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <template v-else>
          <v-list-item v-if="item.route"
            :to="{ name: item.route }"
            @mousedown.native="mousedown(item)"
          >
            <v-list-item-icon>
              <v-icon size="25">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="item.text" />

            <v-btn v-if="index === 0" right icon color="secondary" x-small>
              <v-icon color="primary" v-on:click.prevent="togglePinned()">
                {{ isPinned ? '$thumbtack-pinned' : '$thumbtack-off' }}
              </v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item v-else
            :href="item.href" target="_blank"
          >
              <v-list-item-icon>
                  <v-icon size="25">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title v-text="item.text" />
          </v-list-item>
        </template>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';

import { mapGetters } from 'vuex';
import { isEnabled } from 'vue-feature-flipping';
import adminNavItems from '@/admin/nav';
import isoNavItems from '@/iso/nav';
import merchantNavItems from '@/merchant/nav';

function filterDisabledNavItems(navItems: any[]): any[] {
  return navItems.filter((item) => item.feature === undefined || isEnabled(item.feature));
}

interface TogglePayload {
  isPinned: boolean;
  mini?: boolean;
}

export default Vue.extend({
  props: {
    localStorageKey: {
      type: String,
      required: false,
      default: 'coregateway.navbar.pinned',
    },
  },
  data() {
    return {
      drawer: true as boolean,
      mini: true as boolean,
      isPinned: false as boolean,
    };
  },
  mounted() {
    if (this.localStorageKey) {
      const item = localStorage.getItem(this.localStorageKey);
      if (item) {
        let value;
        try {
          value = JSON.parse(item);
        } finally {
          if (value) {
            Object.assign(this.$data, { isPinned: true, mini: false });
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['currentUserCan', 'context']),
    navbarItems(): any[] {
      if (this.context === 'admin') {
        return filterDisabledNavItems(adminNavItems);
      }
      if (this.context === 'iso') {
        return filterDisabledNavItems(isoNavItems);
      }
      if (this.context === 'merchant') {
        return filterDisabledNavItems(merchantNavItems);
      }

      return [];
    },
    allowedItems(): any[] {
      return this.navbarItems
        .filter((item: any) => !item.permission || this.currentUserCan(item.permission));
    },
  },
  methods: {
    navItemHasChildren: (navItem: any): boolean => navItem.children && navItem.children.length > 0,
    mousedown(item: any) {
      if (this.$route.name === item.route) {
        this.$emit('routerViewReload');
      }
    },
    togglePinned() {
      const isPinned = !this.isPinned;
      const payload: TogglePayload = { isPinned };
      if (!isPinned) {
        payload.mini = true;
      }
      Object.assign(this.$data, payload);
      if (this.localStorageKey) {
        localStorage.setItem(this.localStorageKey, JSON.stringify(isPinned));
      }
    },
  },
});
</script>
