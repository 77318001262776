<template>
  <div class="tw-text-center">
    <img v-if="$store.state.logo"
      :src="$store.state.logo" :alt="$store.state.brandName" class="logo" />
    <icon v-else icon="user-circle" size="8x" class="logo"></icon>
  </div>
</template>

<script>
import Icon from '@/common/icon';

export default {
  components: { Icon },
};
</script>
