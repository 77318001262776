import NotFound from '@/common/views/NotFound.vue';
import Login from '@/common/views/auth/Login.vue';
import ResetPassword from '@/common/views/auth/ResetPassword.vue';
import ConfirmResetPassword from '@/common/views/auth/ConfirmResetPassword.vue';
import SettingsPage from '@/common/components/settings/SettingsPage.vue';
import SupportPage from '@/common/components/settings/SupportPage.vue';
import SecurityKeys from '@/common/components/settings/SecurityKeys.vue';
import ApiTokensPage from '@/common/components/settings/ApiTokensPage.vue';
import Webhooks from '@/common/components/settings/Webhooks.vue';


import adminRoutes from '@/admin/routes';
import isoRoutes from '@/iso/routes';
import merchantRoutes from '@/merchant/routes';
import paymentRoutes from '@/payments/routes';

export const LOGIN_PATH = '/login';

const prefixRoutes = ((routes, prefix) => routes.map((route) => {
  const mapped = { ...route, name: `${prefix}.${route.name}` };

  if (route.redirect?.name) {
    mapped.redirect = { name: `${prefix}.${route.redirect.name}` };
  }
  if (route.children) {
    mapped.children = route.children.map((child) => ({ ...child, name: `${prefix}.${child.name}` }));
  }
  return mapped;
}));

export default [
  {
    path: '/',
    name: 'root',
    redirect: { name: 'merchant.dashboard' },
  },
  ...prefixRoutes(merchantRoutes, 'merchant'),
  ...prefixRoutes(isoRoutes, 'iso'),
  ...prefixRoutes(adminRoutes, 'admin'),
  ...paymentRoutes,
  {
    path: LOGIN_PATH,
    name: 'login',
    component: Login,
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsPage,
  },
  {
    path: '/settings/security-keys',
    name: 'settings.security-keys',
    component: SecurityKeys,
  },
  {
    path: '/settings/api-tokens',
    name: 'settings.api-tokens',
    component: ApiTokensPage,
  },
  {
    path: '/support',
    name: 'support',
    component: SupportPage,
  },
  {
    path: '/password/reset',
    name: 'password-reset',
    component: ResetPassword,
  },
  {
    path: '/password/reset/:token',
    name: 'confirm-password-reset',
    component: ConfirmResetPassword,
  },
  {
    path: '/settings/webhooks',
    name: 'settings.webhooks',
    component: Webhooks,
  },
  {
    path: '*',
    component: NotFound,
  },
];
