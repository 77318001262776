<template>
  <v-row justify="center">
    <v-dialog v-model="idleModal" max-width="600">
      <v-card justify="center" class="p-3">
        <v-card-title class="justify-center">
          <h1 class="red--text">Session Expiring Soon</h1>
          <strong>
            <p> You will be automatically logged out in <br />
            {{ time/1000 }} seconds due to inactivity.</p>
          </strong>
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      time: 59000,
      idleModal: {
        type: Boolean,
        default: true,
      },
    };
  },
  created() {
    const timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
      if (this.time < 1) {
        clearInterval(timerId);
        this.$store.dispatch('logout').then(() => {
          this.$router.push({ name: 'login' });
        });
      }
    }, 1000);
  },
};
</script>
