<template>
  <div :class="headerClass">
    <template v-if="subHeader">
      <div class="middle">
        <h3><slot></slot></h3>
      </div>
    </template>
    <template v-else>
      <div class="left">
        <component :is="tag"><slot></slot></component>
      </div>
      <div class="middle"><slot name="middle"></slot></div>
    </template>
    <div class="right"><slot name="right"></slot></div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    subHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    tag: {
      type: String,
      required: false,
      default: 'h1',
    },
  },
  computed: {
    headerClass(): string {
      return this.subHeader ? 'sub-header' : 'page-header';
    },
  },
});
</script>
