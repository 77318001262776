<template>
  <div>
    <template v-if="loading">
      <slot name="loading">
        <Spinner />
      </slot>
    </template>
    <template v-if="ready">
      <slot></slot>
    </template>
    <template v-if="error">
      <div class="alert alert-error">
        <slot name="error">Something went wrong. Please try again.</slot>
      </div>
    </template>
  </div>
</template>

<script lang='ts'>
import Vue from 'vue';
import Spinner from './LoadingSpinner.vue';
import { PageLoaderStatus } from './pageLoader.types';

export default Vue.extend({
  data() {
    return {
      loaderState: PageLoaderStatus.LOADING as PageLoaderStatus,
    };
  },
  computed: {
    loading(): boolean {
      return this.loaderState === PageLoaderStatus.LOADING;
    },
    ready(): boolean {
      return this.loaderState === PageLoaderStatus.READY;
    },
    error(): boolean {
      return this.loaderState === PageLoaderStatus.ERROR;
    },
  },
  methods: {
    setLoading() {
      this.loaderState = PageLoaderStatus.LOADING;
    },
    setReady() {
      this.loaderState = PageLoaderStatus.READY;
    },
    setError() {
      this.loaderState = PageLoaderStatus.ERROR;
    },
  },
  components: { Spinner },
});
</script>
