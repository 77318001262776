<template>
  <div class="tw-flex-row tw-w-full">
    <div
      class="tw-px-4 tw-border-gray-400"
    >
      <h1>API Tokens</h1>
      <ApiTokens />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import ApiTokens from '@/common/components/settings/ApiTokens.vue';

export default Vue.extend({
  components: {
    ApiTokens,
  },
});
</script>
