<template>
  <div>
    <v-dialog v-model="showModal" @keydown.esc="closeModal" persistent max-width="1000">
      <v-card justify="center" class="p-1">
        <v-card-title>Edit Webhook
          <v-spacer />
          <v-btn icon @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="save" ref="form" lazy-validation>
            <v-container fluid>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="editWebhook.name"
                    :rules="[(v) => !!v || 'Field name is required']"
                    label="Name"
                    outlined
                    dense
                  />
                  <v-text-field
                    v-model="editWebhook.url"
                    :rules="[(v) => !!v || 'Field url is required']"
                    label="URL"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="6">
                  <v-textarea
                    placeholder="Add a description, such as intent (optional)"
                    name="description"
                    height="100"
                    auto-grow
                    v-model="editWebhook.description"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-expansion-panels class="mb-10">
              <v-expansion-panel class="mb-5">
                <v-expansion-panel-header>
                  Events
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col v-for="(event, i) in this.transactionEvents" :key="i" cols="4">
                      <v-checkbox
                        v-model="editWebhook.webhookEvents[i]"
                        outlined
                        dense
                        :label="event"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="mb-5">
                <v-expansion-panel-header>Sources</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col v-for="(source, i)  in this.transactionSources" :key="i" cols="3">
                      <v-checkbox
                        v-model="editWebhook.webhookTransactionSources[i]"
                        outlined
                        dense
                        :label="source.toString().replaceAll('_', ' ').toUpperCase()"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>Hosted Payment Pages</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template>
                      <thead>
                      <tr>
                        <th>HPP Name</th>
                        <th>Status</th>
                        <th>Enable</th>
                      </tr>
                      </thead>
                      <tbody>
                        <template>
                          <tr v-for="(hpp, i)  in this.hpps" :key="i">
                            <td>{{ hpp.name }}</td>
                            <td>{{ formatStatusText(hpp.pageStatus) }}</td>
                            <td>
                              <v-checkbox
                                v-model="editWebhook.hostedPaymentPages[hpp.id]"
                                outlined
                                dense
                                label=""
                              />
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-btn color="secondary" type="submit">Save</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <LoadingModal loadingText="Saving Webhook..." :showModal="isSubmitting" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import api from '@/common/api';
import { toDisplayText } from '@/common/util/hostedPaymentPages.util';
import LoadingModal from '@/common/components/LoadingModal.vue';
import {
  HostedPaymentPageStatus, HostedPaymentPageStatusDisplay,
} from '@/common/types/hostedPaymentPages.types';
import {
  Webhook,
  initWebhook,
  TransactionEvents,
  TransactionSources,
} from './support.types';

export default Vue.extend({
  props: {
    webhookToEdit: {
      type: Object,
      required: true,
    },
    hpps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      editWebhook: initWebhook() as Webhook,
      valid: true,
      isSubmitting: false as boolean,
      showModal: false as boolean,
      transactionEvents: [''] as any,
      transactionSources: [''] as any,
      selectedEvents: {},
      selectedSources: {},
      selectedHppsIds: {},
    };
  },
  mounted() {
    this.assignData();
  },
  watch: {
    webhookToEdit() {
      this.editWebhook = this?.webhookToEdit;
      const allHpps: { [key: string]: boolean } = {};
      this.hpps.forEach((hpp: any) => {
        allHpps[hpp.id] = false;
      });
      this.showModal = true;
      const events:string[] = Object.values(this?.editWebhook.webhookEvents);
      const sources:string[] = Object.values(this?.editWebhook.webhookTransactionSources);
      const hpps = Object.keys(this.editWebhook?.hostedPaymentPages);
      this.editWebhook.hostedPaymentPages = allHpps;

      // selected hpps
      hpps.forEach((item) => {
        this.editWebhook.hostedPaymentPages[item] = true;
      });

      // Selected events
      const selectedEvents: { [key: string]: boolean } = {};
      events.forEach((item) => {
        selectedEvents[item] = true;
      });
      this.editWebhook.webhookEvents = selectedEvents;

      // Selected sources
      const selectedSources: {[key: string]: boolean} = {};
      sources.forEach((item) => {
        selectedSources[item] = true;
      });
      this.editWebhook.webhookTransactionSources = selectedSources;
    },
    newWebhook() {
      this.loadWebhookData();
    },
  },
  methods: {
    assignData() {
      this.selectedEvents = this.editWebhook.webhookEvents;
      this.selectedSources = this.editWebhook.webhookTransactionSources;
      this.selectedHppsIds = this.editWebhook.hostedPaymentPages;

      this.transactionEvents = TransactionEvents;
      this.transactionSources = TransactionSources;
    },
    loadWebhookData() {
      if (this?.editWebhook.name === null
        || Object.keys(this?.editWebhook).length === 0) return;

      this.showModal = true;
      this.assignData();
    },
    formatStatusText(status: HostedPaymentPageStatus) : HostedPaymentPageStatusDisplay {
      return toDisplayText(status);
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    save() {
      if (!this.isValid()) {
        return;
      }
      this.submitActions(true);
      api
        .put(`/webhooks/${this?.editWebhook?.id}`, {
          name: this.editWebhook.name,
          url: this.editWebhook.url,
          description: this.editWebhook.description,
          transactionEvents: this.editWebhook.webhookEvents,
          transactionSources: this.editWebhook.webhookTransactionSources,
          hpps: this.editWebhook.hostedPaymentPages,
        })
        .then(() => {
          this.$emit('updated');
          this.$toasted.success('Webhook edited successfully!');
          (this.$refs.form as any).resetValidation();
          (this.$refs.form as any).reset();
          this.isSubmitting = false;
          this.closeModal();
        })
        .catch(({ response }: { response: any }) => {
          if (typeof response.data !== 'undefined' && typeof response.data.errors !== 'undefined') {
            const errorMessage: any[] = [];
            Object.keys(response.data.errors).forEach((key: string) => {
              errorMessage.push(response.data.errors[key]);
            });
            const message = errorMessage.join('<br />');
            this.$toasted.error(
              message,
            );
          }
          this.isSubmitting = false;
        });
    },
    show() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.$emit('close');
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  components: {
    LoadingModal,
  },
});
</script>
