import { DateTime } from 'luxon';
import Vue from 'vue';

export default function setVueFilters(vue: typeof Vue) {
  const centsFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  vue.filter('dollarsAndCents', (value: number) => centsFormatter.format(value / 100));
  vue.filter('number2Decimals', (value: number) => centsFormatter.format(value));
  const centsFormatterNoCommas = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  });
  vue.filter('dollarsAndCentsNoCommas', (value: number) => centsFormatterNoCommas.format(value / 100));
  const simpleFormatter = new Intl.NumberFormat('en-US', {
    useGrouping: true,
  });
  vue.filter('simpleNumber', (value: number|string) => (value ? simpleFormatter.format(+value) : 0));

  const numberWithGrouping = new Intl.NumberFormat('en-US', {
    useGrouping: true,
  });
  vue.filter('quantityWithCommas', (value: number) => (value ? numberWithGrouping.format(value) : null));

  vue.filter('allCaps', (value: string) => (value ? value.toUpperCase() : ''));
  vue.filter('capitalize', (value: string) => {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
  });

  vue.filter('first15Characters', (value: string) => (value.length > 15 ? `${value.substring(0, 15)}...` : value));


  vue.filter('uuidLast6', (value: string) => (value ? value.slice(-6) : ''));
  vue.filter('uuidLast12', (value: string) => (value ? value.slice(-12) : ''));

  vue.filter('dateShort', (value: Date) => {
    if (value) {
      const dt = DateTime.fromISO(value.toLocaleString());
      const formattedDate = dt.toLocaleString(DateTime.DATE_SHORT);
      return formattedDate;
    }
    return '';
  });
  vue.filter('dateTimeShort', (value: Date) => {
    if (value) {
      const dt = DateTime.fromISO(value.toLocaleString(), { setZone: true });
      const formattedDate = dt.toLocaleString(DateTime.DATETIME_SHORT).replace(', ', '\n');
      return formattedDate;
    }
    return '';
  });
  vue.filter('dateTimeBatches', (value: Date) => {
    if (value) {
      const dt = DateTime.fromISO(value.toLocaleString()).toUTC();
      const formattedDate = dt.toLocaleString(DateTime.DATETIME_SHORT).replace(', ', '\n');
      return formattedDate;
    }
    return '';
  });
  vue.filter('dateTimeShortUtc', (value: Date) => {
    if (value) {
      const dt = DateTime.fromISO(value.toLocaleString()).toUTC();
      const formattedDate = dt.toLocaleString(DateTime.DATETIME_SHORT).replace(', ', '\n');
      return formattedDate;
    }
    return '';
  });
  vue.filter('dateShortUtc', (value: Date) => {
    if (value) {
      const dt = DateTime.fromISO(value.toLocaleString()).toUTC();
      const formattedDate = dt.toLocaleString(DateTime.DATE_SHORT);
      return formattedDate;
    }
    return '';
  });
  vue.filter('dateTimeFull', (value: Date) => {
    if (value) {
      const dt = DateTime.fromISO(value.toLocaleString());
      const formattedDate = dt.toLocaleString(DateTime.DATETIME_FULL).replace(', ', '\n');
      return formattedDate;
    }
    return '';
  });
  vue.filter('dateTimeShortWithAt', (value: Date) => {
    if (value) {
      const dt = DateTime.fromISO(value.toLocaleString(), { setZone: false });
      const formattedDate = dt.toLocaleString(DateTime.DATETIME_SHORT).replace(', ', ' at ');
      return formattedDate;
    }
    return '';
  });
  vue.filter('dateMedium', (value: Date) => {
    if (value) {
      const dt = DateTime.fromISO(value.toLocaleString());
      return dt.toLocaleString(DateTime.DATE_MED);
    }
    return '';
  });
  vue.filter('dateTimeShortUtcWithAt', (value: Date) => {
    if (value) {
      const dt = DateTime.fromISO(value.toLocaleString()).toUTC();
      const formattedDate = dt.toLocaleString(DateTime.DATETIME_SHORT).replace(', ', ' at ');
      return formattedDate;
    }
    return '';
  });
}
