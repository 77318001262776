import { DataTimestamps, JsonApiData } from '@/jsonApi.types';
import { ConvenienceFee } from '@/common/types/convenienceFee.types';
import { Processor } from './processors.types';

export enum HostedPaymentPageStatus {
  NOT_ACTIVE = 'not_active',
  ACTIVE_PENDING = 'active_pending',
  ACTIVE = 'active',
}

export enum HostedPaymentPageStatusDisplay {
  NOT_ACTIVE = 'Not Active',
  ACTIVE_PENDING = 'Active with Pending Changes',
  ACTIVE = 'Active',
}

export interface HostedPaymentPageConfig {
  html: string;
  css: string;
  styles: string;
  components: string;
  assets: string;
}

export enum HostedPaymentPageTypes {
  RECURRING = 'Recurring',
  FIXED_AMOUNT = 'Fixed Amount',
  STANDARD = 'Standard',
}

export interface HostedPaymentPageBase extends JsonApiData, DataTimestamps {
  name: string;
  description?: string | null;
  config: any[];
  isFixedAmount: boolean;
  amount: any;
  redirect: string;
  surchargePercent: number;
  surchargeEnabled: boolean;
  nonCashAdjustmentEnabled: boolean;
  convenienceFeeEnabled: boolean;
  adjustmentName?: string;
  adjustmentPercent?: number;
  adjustmentDollarAmount?: number;
  adjustmentPercentAch?: number;
  adjustmentDollarAmountAch?: number;
  adjustmentInHpp?: boolean;
  merchantContact: object;
  merchantCompanyName: string;
  isRecurring: boolean,
  plan: object,
  hasKeybankProcessor: boolean,
  hasHuntingtonProcessor: boolean,
  isCvvRequired: boolean,
  isCvvVisible: boolean,
  isStandard: boolean,
  minimumAmount: any,
  convenienceFee: ConvenienceFee | null,
}

export interface HostedPaymentPageUpdate extends HostedPaymentPageBase {
  amount: any;
  creditCardProcessorId: string | null;
  achProcessorId: string | null;
  pageStatus: HostedPaymentPageStatus,
}

export interface HostedPaymentPageProcessors {
  credit: Processor | null;
  ach: Processor | null;
}

export interface HostedPaymentPageResponse extends HostedPaymentPageBase {
  pageStatus: HostedPaymentPageStatus,
  renderedUrl?: string;
  processors: HostedPaymentPageProcessors;
}
