<template>
  <div v-if="shown">
    <div class="curtain" v-if="curtain"></div>
    <div class="tw-z-50 tw-h-10 tw-flex tw-fixed tw-left-0 tw-top-0 tw-w-full tw-bg-black">
      <v-btn color="secondary" v-if="showBack" class="tw-bg-black" @click="hide">
        <Icon icon="arrow-left"></Icon>
      </v-btn>
    </div>
    <div class="doc-viewer">
      <v-row v-if="supportPage" justify="end">
        <v-btn small color="red" @click="$emit('closeRatesModal')">X</v-btn>
      </v-row>
      <div class="body">
        <div v-if="showHtml" v-html="html"></div>
        <slot v-else name="body"></slot>
      </div>
      <div
        class="tw-flex tw-justify-center tw-sticky tw-left-0 tw-bottom-0 tw-w-full tw-bg-gray-200"
      >
        <slot name="footer"></slot>
      </div>
    </div>
    <div class="status-bar">
      <slot name="status"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import Icon from '@/common/icon';

export default Vue.extend({
  props: {
    html: {
      type: String,
      required: true,
    },
    curtain: {
      type: Boolean,
      default: true,
    },
    closeOnCurtainClick: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    showHtml: {
      type: Boolean,
      default: true,
    },
    showImmediately: {
      type: Boolean,
      default: false,
    },
    supportPage: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      shown: false as boolean,
    };
  },
  mounted() {
    this.shown = this.showImmediately;
  },
  methods: {
    show() {
      this.shown = true;
    },
    hide() {
      this.shown = false;
    },
    setVisibility(show: boolean) {
      this.shown = show;
    },
  },
  validations: {
    email: {
      required,
    },
  },
  components: {
    Icon,
  },
});
</script>

<style scoped>
  .row {
    margin: 5px 0 0 0;
  }
</style>
